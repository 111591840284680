.navbar.navbar-expand-lg.navbar-dark.bg-dark {
  .container {
    z-index: 100;
  }
}

.navLink {
  border-bottom: transparent 2px solid;
  padding: 5px 0 !important;
  margin: 0px 10px;
  transition-duration: 0.3s;
  color: white !important;
  font-size: 18px;

  &:hover {
    border-bottom: var(--purple-color) 2px solid !important;
  }

  &.active {
    border-bottom: var(--purple-color) 2px solid !important;
  }
}
