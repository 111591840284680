.footerWrapper {
  background-color: var(--teal-color);
  color: white;

  .footerTop {
    width: 100%;
    display: flex;
    padding: 50px;
    flex-wrap: wrap;

    @media screen and (max-width: 870px) {
      justify-content: center;
    }

    @media screen and (max-width: 650px) {
      padding: 20px;
    }

    .footerLeft {
      width: 33%;
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 870px) {
        display: none;
      }

      .footerBrand {
        width: 250px;

        @media screen and (max-width: 1260px) {
          width: 200px;
        }
      }
    }

    .footerMiddle {
      width: 33%;
      justify-content: center;

      @media screen and (max-width: 870px) {
        width: 300px;
      }

      ul {
        list-style: none;
        li {
          margin-bottom: 20px;
          font-size: var(--body-font-size);
        }
      }
    }

    .footerNav {
      display: flex;
      justify-content: center;

      ul:nth-child(1) {
        padding-left: 0;
      }
    }
  }

  .footerRight {
    width: 33%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @media screen and (max-width: 870px) {
      width: 100%;
      align-items: center;
    }

    .footerSocialIcons {
      display: flex;
      gap: 20px;
      a .footerSocialIcon {
        height: 30px;
      }
    }
  }

  .footerBottom {
    display: inline-block;
    text-align: center;
    width: 100%;
  }
}
