.wwdTilesSection {
  display: flex;
  width: 80%;
  margin: auto;
  gap: 50px;
  position: relative;
  margin: 40px auto;
  flex-wrap: wrap;
  align-items: stretch;

  @media screen and (max-width: 1000px) {
    width: 90%;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }

  .wwdTilesDivider {
    width: 1px;
    border-right: 4px dotted var(--purple-color);

    @media screen and (max-width: 800px) {
      height: 1px;
      width: 100%;
      border-bottom: 4px dotted var(--purple-color);
      left: 0;
      top: 46%;
    }
  }
}

.wwdContentBanner {
  width: 100%;
  height: auto;
}
