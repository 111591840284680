@font-face {
  font-family: Invention;
  src: url("../assets/fonts/font-invention/Invention_Rg.ttf");
  font-weight: normal;
}

@font-face {
  font-family: Invention;
  src: url("../assets/fonts/font-invention/Invention_Bd.ttf");
  font-weight: bold;
}

@font-face {
  font-family: Invention;
  src: url("../assets/fonts/font-invention/Invention_It.ttf");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Invention;
  src: url("../assets/fonts/font-invention/Invention_BdIt.ttf");
  font-weight: bold;
  font-style: italic;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Invention, sans-serif !important;
  color: var(--dark-blue-color) !important;
}

a {
  color: inherit !important;
  text-decoration: none !important;
}

* {
  box-sizing: border-box;
}

.navbar.bg-dark.navbar-dark {
  background-color: var(--dark-blue-color) !important;

  .container {
    background-color: var(--dark-blue-color) !important;
    position: absolute;
    top: 0;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 100;
  }

  .navbar-collapse {
    padding: 20px 0 !important;
  }
}

a.nav-link {
  color: white !important;
}

.gallery-coalition-title {
  display: block;
  padding-bottom: 8px;
  font-size: 17px;
}

.gallery-coalition-italics {
  display: block;
  padding-bottom: 8px;
}

.gallery-caption{
  display: block;
  padding-bottom: 8px;
  font-size: 12px;
  color: var(--dark-blue-color);
}