.PageWrapper {
  width: 80%;
  margin: auto;

  @media screen and (max-width: 650px) {
    width: 95%;
  }

  .Header {
    margin: 80px auto 0;

    @media screen and (max-width: 650px) {
      margin: 30px auto 0;
    }

    h1 {
      font-size: 40px;
      font-weight: bolder;

      @media screen and (max-width: 650px) {
        font-size: 32px;
      }
    }

    p {
      font-size: 20px;
      a {
        color: var(--teal-color) !important;
        font-weight: bold !important;
      }

      @media screen and (max-width: 1375px) {
        width: 100%;
      }

      @media screen and (max-width: 650px) {
        font-size: 16px;
      }
    }
    li {
      font-size: 20px;
      @media screen and (max-width: 650px) {
        font-size: 16px;
      }
      a {
        color: var(--teal-color) !important;
        font-weight: bold !important;
      }
    }
  }

  .coalitionsList {
    li {
      margin-bottom: 15px;

      .coalitionName {
        color: rgb(88, 72, 207);
        font-size: 21px;
        font-style: italic;
      }
    }
  }
}
