.homeStatBar {
  width: 100%;
  background-color: var(--teal-color);
  display: flex;
  justify-content: space-between;
  padding: 0 100px;
  color: white;
  position: relative;
  height: 120px;
  gap: 50px;
  z-index: 1;

  @media screen and (max-width: 1500px) {
    padding: 0 80px;
  }

  @media only screen and (max-width: 1300px) {
    padding: 0 20px;
    height: 85px;
  }

  @media only screen and (max-width: 1076px) {
    height: 103px;
  }

  @media screen and (max-width: 1000px) {
    padding: 0 20px 15px;
    height: auto;
  }

  @media only screen and (max-width: 704px) {
    padding: 15px;
    flex-direction: column;
    gap: 30px;
  }

  .homeStat {
    margin-top: -39px;
    text-align: center;

    @media screen and (max-width: 1300px) {
      margin-top: -25px;
    }

    @media screen and (max-width: 1000px) {
      margin-top: -22px;
    }

    @media screen and (max-width: 704px) {
      width: 157px;
      align-self: center;
      margin-top: 0;
    }

    .homeStatNumber {
      font-size: 100px;
      margin-bottom: 0;
      color: white;
      font-weight: bold;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke: 2px;
      line-height: 1;

      p,
      div {
        display: inline;
      }

      @media screen and (max-width: 1350px) {
        font-size: 70px;
      }

      @media screen and (max-width: 1000px) {
        font-size: 60px;
      }

      @media screen and (max-width: 510px) {
        font-size: 40px;
        font-weight: bold;
      }
    }

    .homeStatText {
      font-size: 24px;
      margin: 0;

      @media screen and (max-width: 1300px) {
        font-size: 20px;
      }

      @media screen and (max-width: 510px) {
        font-size: 18px;
      }
    }
  }
}

.homeTileSection {
  display: flex;
  flex-wrap: wrap;
  p {
    a {
      color: var(--teal-color) !important;
      font-weight: bold !important;
    }
  }
  

  .homeTileSmall {
    width: 45%;

    @media screen and (max-width: 810px) {
      width: 100%;

      &.homeTileSwitch {
        order: 2;
      }
    }
  }

  .homeTileLarge {
    width: 55%;

    @media screen and (max-width: 810px) {
      width: 100%;

      &.homeTileSwitch {
        order: 1;
      }
    }
  }

  .homeTextTile {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .homeTileContent {
    width: var(--tile-content-width);

    .homeTileTitle {
      font-size: var(--title-font-size);
      margin: 0;
    }

    .homeTileBody {
      font-size: var(--body-font-size);
      margin: 20px 0;
    }

    @media screen and (max-width: 810px) {
      padding: 30px 0;
    }
  }

  .homeTileImage {
    width: 100%;
  }
}

.blueBg{
  background-color: var(--dark-blue-color);
}

.whiteColor{
  color: var(--white-color);
}