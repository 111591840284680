.whiteCopySection {
  width: 80%;
  margin: auto;
  font-size: 24px;
  color: var(--dark-blue-color);
  padding: 120px 0;

  h2 {
    font-size: 54px;
    font-weight: bolder;
    margin-bottom: 30px;

    @media screen and (max-width: 1275px) {
      font-size: 32px;
    }

    @media screen and (max-width: 550px) {
      font-size: 24px;
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 1275px) {
    font-size: 20px;
    padding: 50px 0;
  }

  @media screen and (max-width: 860px) {
    font-size: 16px;
    padding: 20px 0;
    width: 90%;
  }
}
