.newsPageWrapper {
  width: 80%;
  margin: auto;

  @media screen and (max-width: 650px) {
    width: 95%;
  }

  .newsHeader {
    margin: 80px auto 0;

    @media screen and (max-width: 650px) {
      margin: 30px auto 0;
    }

    h1 {
      font-size: 40px;
      font-weight: bolder;

      @media screen and (max-width: 650px) {
        font-size: 32px;
      }
    }

    p {
      width: 50%;
      font-size: 20px;

      @media screen and (max-width: 1375px) {
        width: 100%;
      }

      @media screen and (max-width: 650px) {
        font-size: 16px;
      }
    }
  }

  .newsTopSection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    @media screen and (max-width: 1375px) {
      grid-template-columns: 1fr;
    }

    .newsTopGrid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;

      @media screen and (max-width: 830px) {
        grid-template-columns: 1fr;
      }
    }
  }

  .newsMidSection {
    .newsMidSectionGrid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;

      @media screen and (max-width: 1200px) {
        grid-template-columns: 1fr;
      }
    }
  }

  .newsBottomSection {
    display: flex;
    margin: auto;
    gap: 50px;
    position: relative;
    margin: 60px auto;
    flex-wrap: wrap;
    align-items: stretch;

    @media screen and (max-width: 1000px) {
      width: 100%;
      flex-direction: column;
      gap: 30px;
    }
    .newsBottomDivider {
      width: 1px;
      border-right: 4px dotted var(--purple-color);

      @media screen and (max-width: 1000px) {
        height: 1px;
        width: 100%;
        border-bottom: 4px dotted var(--purple-color);
        left: 0;
        top: 46%;
      }
    }

    .newsBottomLeft {
      flex: 1;
      padding: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 40px;

      @media screen and (max-width: 650px) {
        padding: 0;
      }

      p {
        text-align: center;
        font-size: 20px;

        @media screen and (max-width: 650px) {
          font-size: 16px;
        }
      }

      img {
        width: 250px;
        @media screen and (max-width: 1000px) {
          width: 200px;
        }
      }
    }

    .newsBottomRight {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h3 {
        font-size: 40px;
        text-align: center;
        font-weight: bolder;

        @media screen and (max-width: 650px) {
          font-size: 32px;
        }
      }

      p {
        font-size: 20px;
        text-align: center;

        @media screen and (max-width: 650px) {
          font-size: 16px;
        }

        a {
          color: var(--teal-color) !important;
          font-weight: bold !important;
        }
      }
    }
  }
}
