.embla {
  overflow: hidden;
  margin-left: none;
  left: 0;
  position: relative;

  .emblaContainer {
    display: flex;
    height: 450px;

    .slideContent {
      position: absolute;
      width: 70%;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      bottom: 50px;
      color: white;

      @media screen and (max-width: 950px) {
        width: 50%;
      }

      .slideHeading {
        font-size: 30px;
        margin-bottom: 0;
        font-weight: bold;

        @media screen and (max-width: 500px) {
          font-size: 24px;
        }
      }

      .slideSubHeading {
        font-size: 18px;
        margin: 5px 0 20px 0;

        @media screen and (max-width: 500px) {
          font-size: 16px;
        }
      }
    }
  }

  .emblaBtn {
    position: absolute;
    z-index: 100;
    top: 50%;
    cursor: pointer;

    .emblaBtnImg path {
      fill: rgba(255, 255, 255, 0.5);
      transition-duration: 0.3s;
    }

    &:hover {
      .emblaBtnImg path {
        fill: white;
      }
    }

    &.emblaPrev {
      left: 20px;
    }

    &.emblaNext {
      right: 20px;
    }
  }
}
