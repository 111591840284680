.wwdTilesSection {
    display: flex;
    width: 80%;
    margin: auto;
    gap: 50px;
    position: relative;
    margin: 40px auto;
    flex-wrap: wrap;
    align-items: stretch;
  
    @media screen and (max-width: 1000px) {
      width: 90%;
    }
  
    @media screen and (max-width: 800px) {
      flex-direction: column;
    }
  
    .wwdTilesDivider {
      width: 1px;
      border-right: 4px dotted var(--purple-color);
  
      @media screen and (max-width: 800px) {
        height: 1px;
        width: 100%;
        border-bottom: 4px dotted var(--purple-color);
        left: 0;
        top: 46%;
      }
    }
  }
  
  .wwdContentBanner {
    width: 100%;
    height: auto;
  }
  
  .whiteCopySection {
    width: 80%;
    margin: auto;
    font-size: 24px;
    color: var(--dark-blue-color);
    padding: 65px 0;
  
    h2 {
      font-size: 54px;
      font-weight: bolder;
      margin-bottom: 30px;
  
      @media screen and (max-width: 1275px) {
        font-size: 32px;
      }
  
      @media screen and (max-width: 550px) {
        font-size: 24px;
        margin-bottom: 20px;
      }
    }
  
    @media screen and (max-width: 1275px) {
      font-size: 20px;
      padding: 50px 0;
    }
  
    @media screen and (max-width: 860px) {
      font-size: 16px;
      padding: 20px 0;
      width: 90%;
    }
  }

  .homeTileSection {
    display: flex;
    flex-wrap: wrap;
    p {
      a {
        color: var(--teal-color) !important;
        font-weight: bold !important;
      }
    }
    
  
    .homeTileSmall {
      width: 45%;
  
      @media screen and (max-width: 810px) {
        width: 100%;
  
        &.homeTileSwitch {
          order: 2;
        }
      }
    }
  
    .homeTileLarge {
      width: 55%;
  
      @media screen and (max-width: 810px) {
        width: 100%;
  
        &.homeTileSwitch {
          order: 1;
        }
      }
    }
  
    .homeTextTile {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .homeTileContent {
      width: var(--tile-content-width);
  
      .homeTileTitle {
        font-size: var(--title-font-size);
        margin: 0;
      }
  
      .homeTileBody {
        font-size: var(--body-font-size);
        margin: 20px 0;
      }
  
      @media screen and (max-width: 810px) {
        padding: 30px 0;
      }
    }
  
    .homeTileImage {
      width: 100%;
    }
  }
  

.videoContainer {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: #000;
    margin-top:20px;
}

.videoContainer iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.videoContainer video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.marginTop{
  margin-top:48px;
}

@media (max-width: 1652px) {
  .marginTopAisha{
    margin-top:48px;
}

}