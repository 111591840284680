:root {
  --title-font-size: 50px;
  --tile-content-width: 70%;
  --body-font-size: 18px;
  --teal-color: rgb(0, 133, 124);
  --dark-blue-color: rgb(12, 35, 66);
  --purple-color: rgb(88, 72, 207);
  --white-color: rgb(255,255,255);
}

@media screen and (max-width: 1362px) {
  :root {
    --title-font-size: 35px;
    --tile-content-width: 80%;
  }
}

@media screen and (max-width: 1050px) {
  :root {
    --title-font-size: 28px;
    --tile-content-width: 92%;
    --body-font-size: 16px;
  }
}
