.embla {
    overflow: hidden;
    margin-left: none;
    left: 0;
    position: relative;
    margin-bottom: 40px;
  
    .emblaContainer {
      display: flex;
  
      &:first-child {
        padding-left: 0 !important;
      }
  
      &:last-child {
        padding-right: 0 !important;
      }
  
      .slideContent {
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        // color: black;
        color: var(--dark-blue-color);
        padding: 20px;
        padding-bottom: 0;
        background-color: rgb(242, 242, 242);
        height: 100%;
  
        .slideHeading {
          font-size: 30px;
          margin-bottom: 0;
          font-weight: bold;
  
          @media screen and (max-width: 500px) {
            font-size: 24px;
          }
        }
  
        .slideSubHeading {
          font-size: 15px;
          margin: 5px 0 20px 0;
  
          @media screen and (max-width: 500px) {
            font-size: 14px;
          }
  
          a {
            color: var(--teal-color) !important;
            font-weight: bold !important;
          }
        }
      }
    }
  
    .emblaBtn {
      position: absolute;
      z-index: 100;
      top: 180px;
      cursor: pointer;
  
      .emblaBtnImg path {
        fill: rgba(255, 255, 255, 0.5);
        transition-duration: 0.3s;
      }
  
      &:hover {
        .emblaBtnImg path {
          fill: white;
        }
      }
  
      &.emblaPrev {
        left: 20px;
      }
  
      &.emblaNext {
        right: 20px;
      }
  
      @media only screen and (max-width: 720px) {
        top: 140px;
      }
    }
  }
  
  .videoContainer {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: #000;
    margin-top:20px;
}

.videoContainer iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.videoContainer video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 1725px) {
  .marginTopMama{
    margin-top:48px;
}

}